// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".worksEverywhere_pD-3W{display:flex;flex-direction:column;max-width:1140px;overflow:visible;padding:60px 20px;position:relative;text-align:center}.details_FhwUR{margin:0 auto 80px;text-align:left}.bundle_9Ip4b{height:80vw;margin:auto;position:relative;width:100%}.bundle_9Ip4b img{height:40%;width:65%}.bundle_9Ip4b div{align-items:center;border-radius:50%;box-shadow:1px 8px 20px 0 hsla(73,44%,87%,.75);display:flex;height:32vw;justify-content:center;position:absolute;width:32vw}.bundle_9Ip4b div:first-of-type{background-color:hsla(73,44%,87%,.59);left:auto;right:4vw;top:20vw;transform:scale(.85)}.bundle_9Ip4b div:nth-of-type(2){left:26vw;top:21vw;transform:scale(.4)}.bundle_9Ip4b div:nth-of-type(3){background-color:hsla(73,44%,87%,.59);left:0;top:40vw;transform:scale(.97)}.bundle_9Ip4b div:nth-of-type(4){left:37vw;top:51vw;transform:scale(.67)}.bundle_9Ip4b div:nth-of-type(5){background-color:hsla(73,44%,87%,.59);left:12vw;top:0;transform:scale(.77)}.bundle_9Ip4b div:nth-of-type(6){left:44vw;top:-30px;transform:scale(.61)}@media screen and (min-width:768px){.worksEverywhere_pD-3W{padding:360px 20px 120px}.details_FhwUR{margin:0}}@media screen and (min-width:1140px){.worksEverywhere_pD-3W{flex-direction:row;padding:260px 0 120px;text-align:left}.bundle_9Ip4b{height:37vw;width:50%}.bundle_9Ip4b div{height:14vw;width:14vw}.bundle_9Ip4b div:first-of-type{left:27vw;right:auto;top:18vw}.bundle_9Ip4b div:nth-of-type(2){left:16vw;top:11vw}.bundle_9Ip4b div:nth-of-type(3){left:-3vw;top:10vw;transform:scale(.55)}.bundle_9Ip4b div:nth-of-type(4){left:8vw;top:19vw}.bundle_9Ip4b div:nth-of-type(5){left:12vw;top:0}.bundle_9Ip4b div:nth-of-type(6){left:28vw;top:-12px}}@media screen and (min-width:1900px){.bundle_9Ip4b div{height:12vw;width:12vw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"worksEverywhere": "worksEverywhere_pD-3W",
	"details": "details_FhwUR",
	"bundle": "bundle_9Ip4b"
};
module.exports = ___CSS_LOADER_EXPORT___;
