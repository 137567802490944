
export default {
  name: "SupportSection",
  props: ['zone'],
  methods: {
    scrollTo(position) {
      this.$store.dispatch('triggerScrollTo', position);
    },
  }
}
